/*
Since Ionic doesn't have options for globally configuring text in the framework,
here's some Vue directives that make things a little easier
*/

import { i18n } from "@/lib/i18n";

function localizeElement(el: HTMLElement) {
	const type = el.tagName.toLowerCase();

	switch (type) {
		case "ion-select":
			el.setAttribute("ok-text", i18n.global.t("common.ok"));
			el.setAttribute("cancel-text", i18n.global.t("common.cancel"));
			break;
	}
}

export default {
	beforeMount: (el: HTMLElement, _binding: any, _vnode: any) => localizeElement(el),
	beforeUpdate: (el: HTMLElement, _binding: any, _vnode: any) => localizeElement(el),
};
