import { PersistedQuery, AsyncStorage } from "@tanstack/query-persist-client-core";
import { get, set, del, createStore, clear } from "idb-keyval";

/**
 * Creates an IndexedDB store consistent with the AsyncStorage interface
 * that TanStack Query expects.
 * @param dbName
 * @param storeName
 * @returns
 */
export function newIdbAsyncStorage(
	dbName: string,
	storeName: string,
): AsyncStorage<PersistedQuery> & { clear(): Promise<void> } {
	const idbStore = createStore(dbName, storeName);

	return {
		getItem: async (key) => await get(key, idbStore),
		setItem: async (key, value) => await set(key, value, idbStore),
		removeItem: async (key) => await del(key, idbStore),
		clear: async () => await clear(idbStore),
	};
}
