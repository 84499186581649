import showInstallPossibleModal from "@/components/install/InstallPossibleModal";
import moment from "moment";

function getPWADisplayMode() {
	const isStandalone = window.matchMedia("(display-mode: standalone)").matches;
	if (document.referrer.startsWith("android-app://")) {
		return "twa";
		// @ts-expect-error (navigator.standalone only exists on iOS and TS doesn't understand that)
	} else if (navigator.standalone || isStandalone) {
		return "standalone";
	}
	return "browser";
}

window.addEventListener("beforeinstallprompt", (e) => {
	e.preventDefault();
	deferredPWAPrompt = e;
	tryShowInstallPrompt();
});

/**
 * Shows a modal prompting the user to install Seedgreen, but only if the user has not
 * recently dismissed the prompt. (see denyInstallPrompt)
 */
export function tryShowInstallPrompt() {
	if (isPWA) return;

	const nextInstallPrompt = moment(localStorage.getItem("nextInstallPromptTime"));

	if (moment().isBefore(nextInstallPrompt)) return;

	showInstallPossibleModal(true);
}

/**
 * Call when the user dismisses an automatic "Seedgreen can be installed" promtp.
 * Applies exponential falloff to the time of the next automatic prompt.
 */
export function denyInstallPrompt() {
	const dismissedCount = Number(localStorage.getItem("installDismissedCount"));
	const nextInstallPrompt = moment(localStorage.getItem("nextInstallPromptTime") ?? undefined);

	switch (dismissedCount) {
		case 0:
			nextInstallPrompt.add(1, "day");
			break;
		case 1:
			nextInstallPrompt.add(1, "week");
			break;
		case 2:
			nextInstallPrompt.add(2, "month");
			break;
		default:
			nextInstallPrompt.add(6, "month");
	}

	localStorage.setItem("installDismissedCount", String(dismissedCount + 1));
	localStorage.setItem("nextInstallPromptTime", nextInstallPrompt.toISOString());
}

export let deferredPWAPrompt: Event | undefined;
export const isPWA = getPWADisplayMode() === "standalone";
