import harvestOverview from "@/api/harvest-overview";
import headSizeColorMap from "@/api/head-size-color-map";
import alerts from "@/api/alerts";
import notes from "@/api/notes";
import userStatistics from "@/api/user-statistics";
import harvestAcres from "@/api/harvest-acres";
import irrigation from "@/api/irrigation";
import soilSample from "@/api/soil-sample";
import chemical from "@/api/chemical";
import averageHeadWeight from "@/api/average-head-weight";
import bulkBins from "@/api/bulk-bins";
import bulkWeight from "@/api/bulk-weight";
import tasks from "@/api/tasks";
import taskRequest from "@/api/task-request";
import plantingTicket from "@/api/planting-ticket";

const apis = {
	"headSizeColorMap.get": headSizeColorMap._get,
	"alerts.delete": alerts._delete,
	"alerts.clearAll": alerts._clearAll,
	"harvestOverview.createRanchYield": harvestOverview._createRanchYield,
	"notes.create": notes._create,
	"notes.delete": notes._delete,
	"notes.edit": notes._edit,
	"notes.uploadImages": notes._uploadImages,
	"userStatistics.clear": userStatistics._clear,
	"userStatistics.update": userStatistics._update,
	"tasks.put": tasks._put,
	"userStatistics.batchUpdate": userStatistics._batchUpdate,
	"harvestAcres.update": harvestAcres._update,
	"irrigation.loadFromPlanting": irrigation._loadFromPlanting,
	"irrigation.loadFromIrrigationBlock": irrigation._loadFromIrrigationBlock,
	"irrigation.create": irrigation._create,
	"irrigation.update": irrigation._update,
	"irrigation.delete": irrigation._delete,
	"irrigation.createActivity": irrigation._createActivity,
	"irrigation.updateActivity": irrigation._updateActivity,
	"irrigation.deleteActivity": irrigation._deleteActivity,
	"soilSample.create": soilSample._create,
	"soilSample.update": soilSample._update,
	"soilSample.delete": soilSample._delete,
	"soilSample.loadFromPlanting": soilSample._loadFromPlanting,
	"soilSample.loadFromIrrigationBlock": soilSample._loadFromIrrigationBlock,
	"chemical.create": chemical._create,
	"chemical.update": chemical._update,
	"chemical.delete": chemical._delete,
	"chemical.loadFromPlanting": chemical._loadFromPlanting,
	"plantingTicket.saveRecord": plantingTicket._savePlantingTicketRecord,
	"plantingTicket.submitRecord": plantingTicket._submitPlantingTicketRecord,
	"plantingTicket.deleteRecord": plantingTicket._deletePlantingTicketRecord,
	"plantingTicket.deleteDrafts": plantingTicket._deletePlantingTicketDrafts,
	"taskRequest.createOrUpdate": taskRequest._createOrUpdate,
	"averageHeadWeight.update": averageHeadWeight._update,
	"bulkBins.update": bulkBins._update,
	"bulkWeight.update": bulkWeight._update,
};

export default apis;
