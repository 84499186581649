import OfflineQueue from "@/api/offline-queue";
import { ensureValidToken } from "@/api/token-validation";
import store from "@/store";
import { ChemicalTask } from "seedgreen-shared/models/ChemicalTask";
import { AxiosResponse } from "axios";
import seedgreenAPI from "@/lib/seedgreen-api";

const abortSignals: { [key: string | number]: AbortController } = {};

const api = {
	loadFromPlanting(plantingId?: number) {
		console.debug("api.chemical.loadFromPlanting", plantingId);
		if (!plantingId) {
			console.debug("plantingId is null, skipping api.chemical.loadFromPlanting");
			return Promise.resolve();
		}

		// Cancel any pending loads of the same planting if they already exist
		if (abortSignals[plantingId]) {
			abortSignals[plantingId].abort();
		}
		abortSignals[plantingId] = new AbortController();

		const payload = {
			value: plantingId,
		};

		OfflineQueue.add("chemical.loadFromPlanting", payload, function duplicateMerger(queueItem) {
			// Unqueue actions that are rendered obsolete by the new action
			if (queueItem.endpoint === "chemical.loadFromPlanting" && queueItem.payload.value === payload.value)
				return 1;
		});
		return Promise.resolve();
	},
	_loadFromPlanting(payload: { value: number }) {
		console.debug("api.chemical._loadFromPlanting", payload.value);
		return seedgreenAPI
			.get("Chemical", {
				params: {
					plantingId: payload.value,
				},
				signal: abortSignals[payload.value]?.signal,
			})
			.then((response: AxiosResponse<ChemicalTask[]>) => {
				response.data.forEach((task) => store.dispatch("updateChemicalTask", task));
			});
	},
	create: function (value: ChemicalTask) {
		console.debug("api.chemical.create", JSON.stringify(value));
		value.virtualId = window.URL.createObjectURL(new Blob([])).split("/").pop();

		OfflineQueue.add("chemical.create", value);

		// Update local state
		return store.dispatch("createChemicalTask", value);
	},
	_create: function (payload: ChemicalTask) {
		console.debug("api.chemical._create", JSON.stringify(payload));
		return new Promise((resolve, reject) => {
			ensureValidToken()
				.then(() => {
					seedgreenAPI
						.put("Chemical", payload)
						.then((response) => response.data as ChemicalTask)
						.then((updated) => {
							updated.virtualId = payload.virtualId;
							store
								.dispatch("commitChemicalTask", updated)
								.then(() => {
									if (updated.location?.plantingIds?.[0]) {
										return api.loadFromPlanting(updated.location.plantingIds[0]);
									}
								})
								.then(() => resolve(updated));
						})
						.catch(reject);
				})
				.catch(reject);
		});
	},
	update: function (value: ChemicalTask) {
		console.debug("api.chemical.update", JSON.stringify(value));

		// Enqueue API-related mutations
		OfflineQueue.add("chemical.update", value, function duplicateMerger(queueItem) {
			// Unqueue actions that are rendered obsolete by the new action
			if (
				queueItem.endpoint === "chemical.update" &&
				(queueItem.payload.id === value.id || queueItem.payload.virtualId == value.virtualId)
			)
				return 1;
		});

		// Update local state
		return store.dispatch("updateChemicalTask", value);
	},
	_update: function (payload: ChemicalTask) {
		console.debug("api.chemical._update", JSON.stringify(payload));

		return new Promise((resolve, reject) => {
			ensureValidToken()
				.then(() => {
					seedgreenAPI
						.put("Chemical", payload)
						.then((response) => response.data as ChemicalTask)
						.then((data) => {
							console.debug("api.chemical._update.done", JSON.stringify(data));
							payload.id = data.id;
							store
								.dispatch("updateChemicalTask", payload)
								.then(() => {
									if (data.location?.plantingIds?.[0]) {
										return api.loadFromPlanting(data.location.plantingIds[0]);
									}
								})
								.then(() => resolve(payload));
						})
						.catch((e) => {
							reject(e);
						});
				})
				.catch((e) => {
					reject(e);
				});
		});
	},
	delete: function (value: ChemicalTask) {
		const payload = value;

		// Enqueue API-related mutations
		OfflineQueue.add("chemical.delete", payload, function duplicateMerger(queueItem) {
			// Unqueue actions that are rendered obsolete by the new action
			if (
				queueItem.endpoint === "chemical.delete" &&
				(queueItem.payload.id === payload.id || queueItem.payload.virtualId == payload.virtualId)
			)
				return 1;
		});

		// Update local state
		return store.dispatch("deleteChemicalTask", value);
	},
	_delete: function (payload: ChemicalTask) {
		return new Promise((resolve, reject) => {
			ensureValidToken()
				.then(() => {
					seedgreenAPI
						.delete("Chemical", { data: payload })
						.then((response) => response.data as ChemicalTask)
						.then((data) => {
							resolve(data);
							if (payload.location?.plantingIds?.[0])
								api.loadFromPlanting(payload.location.plantingIds[0]);
						})
						.catch((e) => {
							reject(e);
						});
				})
				.catch((e) => {
					reject(e);
				});
		});
	},
};
export default api;
