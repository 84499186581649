import store from "@/store";
import _ from "lodash";
import seedgreenAPI from "./seedgreen-api";

/* Type handling */
function coerceToArrayBuffer(thing: any, name?: any) {
	if (typeof thing === "string") {
		// base64url to base64
		thing = thing.replace(/-/g, "+").replace(/_/g, "/");

		// base64 to Uint8Array
		const str = window.atob(thing);
		const bytes = new Uint8Array(str.length);
		for (let i = 0; i < str.length; i++) {
			bytes[i] = str.charCodeAt(i);
		}
		thing = bytes;
	}

	// Array to Uint8Array
	if (Array.isArray(thing)) {
		thing = new Uint8Array(thing);
	}

	// Uint8Array to ArrayBuffer
	if (thing instanceof Uint8Array) {
		thing = thing.buffer;
	}

	// error if none of the above worked
	if (!(thing instanceof ArrayBuffer)) {
		throw new TypeError("could not coerce '" + name + "' to ArrayBuffer");
	}

	return thing;
}

function coerceToBase64Url(thing: any) {
	// Array or ArrayBuffer to Uint8Array
	if (Array.isArray(thing)) {
		thing = Uint8Array.from(thing);
	}

	if (thing instanceof ArrayBuffer) {
		thing = new Uint8Array(thing);
	}

	// Uint8Array to base64
	if (thing instanceof Uint8Array) {
		let str = "";
		const len = thing.byteLength;

		for (let i = 0; i < len; i++) {
			str += String.fromCharCode(thing[i]);
		}
		thing = window.btoa(str);
	}

	if (typeof thing !== "string") {
		throw new Error("could not coerce to string");
	}

	// base64 to base64url
	// NOTE: "=" at the end of challenge is optional, strip it off here
	thing = thing.replace(/\+/g, "-").replace(/\//g, "_").replace(/=*$/g, "");

	return thing;
}

/* Attestation */

// get options from server
export async function getAttestationOptions() {
	return await seedgreenAPI.post("Fido2/AttestationOptions", {});
}

// convert to credential creation request
export function credentialCreationOptions(attestationOptions: any) {
	const opts = _.cloneDeep(attestationOptions?.data || {});
	// W3C spec wants array buffers
	opts.challenge = coerceToArrayBuffer(opts.challenge);
	opts.user.id = coerceToArrayBuffer(opts.user.id);
	opts.excludeCredentials.forEach((c: any) => {
		c.id = coerceToArrayBuffer(c.id);
	});
	return {
		publicKey: opts,
	};
}

// create credentials
export async function createCredentials(credentialCreationOptions: any) {
	return await navigator.credentials.create(credentialCreationOptions);
}

// convert to attestation request
export function attestationRequest(attestationCredentials: any) {
	if (!attestationCredentials.response) return {};
	const attestationObject = new Uint8Array(attestationCredentials.response.attestationObject);
	const clientDataJSON = new Uint8Array(attestationCredentials.response.clientDataJSON);
	const rawId = new Uint8Array(attestationCredentials.rawId);

	return {
		id: attestationCredentials.id,
		rawId: coerceToBase64Url(rawId),
		type: attestationCredentials.type,
		extensions: attestationCredentials.getClientExtensionResults(),
		response: {
			AttestationObject: coerceToBase64Url(attestationObject),
			clientDataJSON: coerceToBase64Url(clientDataJSON),
		},
	};
}

// send attestation request to server
export async function attestIdentity(attestationRequest: any) {
	return await seedgreenAPI.post("Fido2/Attestation", attestationRequest, {});
}

/* Assertion */

// get options from server
export async function getAssertionOptions() {
	return await seedgreenAPI.post("Fido2/AssertionOptions", {});
}

// convert to credential retrieval request
export function credentialRetrievalOptions(assertionOptions: any) {
	const opts = _.cloneDeep(assertionOptions?.data || {});
	// W3C spec wants array buffers
	opts.challenge = coerceToArrayBuffer(opts.challenge);
	opts.allowCredentials.forEach((c: any) => {
		c.id = coerceToArrayBuffer(c.id);
	});
	return {
		publicKey: opts,
	};
}

// get credentials
export async function getCredentials(credentialRetrievalOptions: any) {
	return await navigator.credentials.get(credentialRetrievalOptions);
}

// convert to assertion request
export function assertionRequest(assertionCredentials: any) {
	if (!assertionCredentials.response) return {};

	// Move data into Arrays incase it is super long
	const authData = new Uint8Array(assertionCredentials.response.authenticatorData);
	const clientDataJSON = new Uint8Array(assertionCredentials.response.clientDataJSON);
	const rawId = new Uint8Array(assertionCredentials.rawId);
	const sig = new Uint8Array(assertionCredentials.response.signature);
	const userHandle = new Uint8Array(assertionCredentials.response.userHandle);
	return {
		id: assertionCredentials.id,
		rawId: coerceToBase64Url(rawId),
		type: assertionCredentials.type,
		extensions: assertionCredentials.getClientExtensionResults(),
		response: {
			authenticatorData: coerceToBase64Url(authData),
			clientDataJSON: coerceToBase64Url(clientDataJSON),
			userHandle: userHandle !== null ? coerceToBase64Url(userHandle) : null,
			signature: coerceToBase64Url(sig),
		},
	};
}

// send assertion request to server
export async function assertIdentity(assertionRequest: any) {
	return await seedgreenAPI.post("Fido2/Assertion", assertionRequest);
}
