<template>
	<div style="display: flex">
		<div class="flatpickr-custom-wrapper flatpickr-fill" style="padding: 5px 2px 5px 0; width: 100%">
			<input
				ref="dateInput"
				type="date"
				:value="date"
				@input="$emit('update:date', $event.target.value)"
				:placeholder="capitalizeFirstLetter($t('common.select-date').toLowerCase()) + '...'"
			/>
		</div>
		<ion-button v-if="!required && showClearButton" @click="clear()" color="light">X</ion-button>
	</div>
</template>

<style>
	.flatpickr-custom-wrapper > input.form-control {
		background-color: var(--ion-color-light);
	}
</style>

<script>
	import $ from "jquery";

	import flatpickr from "flatpickr";
	import fpLocales from "flatpickr/dist/l10n";
	import "flatpickr/dist/flatpickr.min.css";
	import weekSelect from "flatpickr/dist/plugins/weekSelect/weekSelect";

	import { mapGetters, mapState } from "vuex";
	import { IonButton } from "@ionic/vue";
	import { capitalizeFirstLetter } from "@/utils";

	export default {
		name: "DateInput",
		components: { IonButton },
		props: {
			date: {},
			minDate: { type: Date, default: null },
			defaultDate: { default: null },
			maxDate: { type: Date, default: null },
			required: { type: Boolean, default: true },
			showClearButton: { type: Boolean, default: true },
			autoFocus: { type: Boolean, default: false },
			inline: { type: Boolean, default: false },
			mode: { type: String, default: "single" },
		},
		emits: ["update:date"],
		data() {
			return {
				fp: null,
				capitalizeFirstLetter,
			};
		},
		created() {
			setTimeout(this.initDatePicker, 10);
		},
		mounted() {
			if (this.autoFocus)
				setTimeout(() => {
					let el = $(this.$refs.dateInput?.nextSibling);
					if (el) el.focus();
				}, 500);
		},
		unmounted() {
			// remove the calendar popup
			$(".flatpickr-calendar").remove();
			if (this.fp) this.fp.destroy();
		},
		methods: {
			initDatePicker() {
				// Initialize custom date picker
				this.fp = flatpickr(this.$refs.dateInput, {
					weekNumbers: document.documentElement.clientWidth >= 400,
					altInput: true,
					altFormat: "M j, Y",
					disableMobile: true,
					minDate: this.minDate,
					maxDate: this.maxDate,
					defaultDate: this.modeCheckedDefaultDate,
					inline: this.inline,
					mode: this.mode,
					onOpen: (dates, str, inst) => {
						$(inst._input).addClass("selected-input");
					},
					onClose: (dates, str, inst) => {
						this.$emit("update:date", str);
						$(inst._input).removeClass("selected-input");
					},
					getWeek: this.getWeek,
					locale: {
						...fpLocales[this.locale],
						firstDayOfWeek: this.user.startDayOfWeek,
					},
					// Extra options needed for week select
					...(this.mode == "week"
						? {
								plugins: [new weekSelect({})],
								onChange: this.formatAltInput,
							}
						: {}),
				});
			},
			refreshDatePicker() {
				$(".flatpickr-calendar").remove();
				this.fp.destroy();
				this.initDatePicker();
			},
			clear() {
				this.$emit("update:date", null);
				this.fp.clear();
			},
			getWeek(date) {
				return this.newWeekOfYear(date, this.user.startDayOfWeek).week;
			},
			getWeekStr(date) {
				if (!date) return capitalizeFirstLetter(this.$t("common.select-week").toLowerCase());
				return `${this.$t("time.week")}: ` + this.getWeek(date);
			},
			formatAltInput(dates, str, inst) {
				inst.altInput.value = this.getWeekStr(dates[0]);
			},
		},
		computed: {
			...mapState(["user", "locale"]),
			...mapGetters(["newWeekOfYear"]),
			/** We need to do this extra logic with default date in order to preload dates into Flatpickr.
                (Due to a bug in FP that will probably not get fixed anytime soon, see: 
                    https://github.com/flatpickr/flatpickr/issues/2952)
                This ensures that the wrong type of defaultDate isn't passed in depending on FP's mode. */
			modeCheckedDefaultDate() {
				if (this.mode === "single" && typeof this.defaultDate === "string") return this.defaultDate;
				if (this.mode === "range" && typeof this.defaultDate === "string") {
					return this.defaultDate.split(",");
				}

				return null;
			},
		},
		watch: {
			date(val) {
				if (this.fp) {
					this.fp.setDate(val);
				}
			},
		},
	};
</script>

<style>
	.input-wrapper {
		display: flex;
		margin-bottom: 3px;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
		border-radius: 2px;
		background: #093548;
	}

	.input-wrapper input {
		width: 100%;
		border: none;
	}

	.input-wrapper input:not([disabled]) {
		text-align: center;
		border-top-left-radius: 2px;
		border-bottom-left-radius: 2px;
	}

	.input-wrapper input[disabled] {
		text-align: center;
		background: #dadada;
		color: rgba(0, 0, 0, 0.7);
	}

	.input-wrapper span {
		color: white;
		padding: 12px;
		flex: 0 0 125px;
		border-top-right-radius: 2px;
		border-bottom-right-radius: 2px;
	}

	.date-modal .modal-wrapper {
		max-width: 270px;
		max-height: 180px;
	}

	.flatpickr-calendar.open {
		pointer-events: auto;
	}

	.flatpickr-input + [placeholder] {
		text-align: center;
		transition: 0.2s;
	}

	.flatpickr-custom-wrapper {
		position: relative;
	}

	.flatpickr-custom-wrapper:after {
		font-family: "Font Awesome 5 Free";
		content: "\f073";
		position: absolute;
		top: 10px !important;
		right: 7px;
		font-size: 18px;
		pointer-events: none;
	}

	.flatpickr-fill .flatpickr-input + [placeholder] {
		width: 100%;
		text-align: left;
		padding-left: 8px;
		box-shadow: 0 0.25px 1px rgba(0, 0, 0, 0.4);
		background-color: white;
		border: none;
		padding: 4px;
		font-size: 16pt;
	}
</style>
