import { toRaw } from "vue";
import OfflineQueue from "@/api/offline-queue";
import { ensureValidToken } from "@/api/token-validation";
import store from "@/store";
import seedgreenAPI from "@/lib/seedgreen-api";
import localForage from "localforage";

const bundle = {
	// Get images path
	getImagesPath: function (note: any) {
		return note._noteId ? `note/_${note._noteId}/images` : `note/${note.noteId}/images`;
	},

	// Migrate the images from the _noteId path to the noteId one
	renameImagesPath: function (_noteId: any, noteId: any) {
		const oldPath = `note/_${_noteId}/images`;
		const newPath = `note/${noteId}/images`;

		return new Promise<void>((resolve) => {
			// Get the images from the old path
			localForage.getItem(oldPath).then((images) => {
				// Store the images in the new path
				localForage.setItem(newPath, toRaw(images)).then(() => {
					// Remove the images from the new path
					localForage.removeItem(oldPath);

					resolve();
				});
			});
		});
	},

	// Store images (locally)
	storeImages: function (note: any, images: any) {
		return localForage.setItem(bundle.getImagesPath(note), toRaw(images));
	},

	// Retrieve images
	retrieveImages: function (note: any) {
		return localForage.getItem(bundle.getImagesPath(note));
	},

	// Upload images (remotely)
	uploadImages: function (note: any) {
		bundle.retrieveImages(note).then((images: any) => {
			if (images && images.length) {
				OfflineQueue.add("notes.uploadImages", note);
			} else {
				localForage.removeItem(bundle.getImagesPath(note));
			}
		});
	},
	_uploadImages: function (note: any) {
		return new Promise<void>((resolve, reject) => {
			bundle.retrieveImages(note).then((images: any) => {
				if (images.length) {
					const image = images.shift();

					seedgreenAPI
						.post("LotDetails/AddNoteImage/" + note.noteId, image)
						.then((response) => response.data)
						.then((createdImage) => {
							// Add the image to the note
							store.dispatch("addNoteImage", {
								noteId: note.noteId,
								noteImage: createdImage,
							});

							// Try and upload additional images
							bundle.storeImages(note, images).then(() => bundle.uploadImages(note));

							resolve();
						})
						.catch((e) => {
							reject(e);
						});
				} else {
					resolve();
				}
			});
		});
	},

	// TODO: Remove images
	removeImages: function (_note: any, _images: any) {},
	_removeImages: function (_note: any, _images: any) {},

	// Create
	create: function (note: any, images: any) {
		// Update local state
		store.dispatch("addNote", note);

		// Store blob images
		bundle.storeImages(note, images).then(() => {
			OfflineQueue.add("notes.create", note);
		});
	},
	_create: function (note: any) {
		return new Promise<void>((resolve, reject) => {
			// Retrieve blob images
			ensureValidToken()
				.then(() => {
					const data = {
						PlantingIds: note.plantingIds,
						NoteText: note.noteText,
						NoteTypeId: note.noteTypeId,
						NoteUrgencyId: note.noteUrgencyId,
						Location: note.location,
						Accuracy: note.accuracy,
						Observations: note.observations,
					};

					seedgreenAPI
						.post("LotDetails/AddNote/" + note.plantingIds[0], data)
						.then((response) => response.data)
						.then((createdData) => {
							const _noteId = note._noteId;
							const noteId = createdData.noteId;

							// Fill in noteId of note with matching _noteId, images with the created image URLs, and update the createdDate
							store.dispatch("commitNote", {
								_noteId: note._noteId,
								plantingIds: note.plantingIds,
								noteId: createdData.noteId,
								noteImages: createdData.images,
								createdDate: new Date().toISOString(),
								observations: createdData.observations,
							});

							// Rename note images path
							bundle.renameImagesPath(_noteId, noteId).then(() => bundle.uploadImages(note));

							resolve();
						})
						.catch((e) => {
							reject(e);
						});
				})
				.catch((e) => {
					reject(e);
				});
		});
	},

	// Edit
	edit: function (note: any, images: any) {
		// Update local state
		store.dispatch("editNote", note);

		// Store mixed blob/URL images
		bundle.storeImages(note, images).then(() => {
			// Enqueue API-related mutations
			OfflineQueue.add("notes.edit", note, function duplicateMerger(queueItem) {
				if (queueItem.endpoint === "notes.create") {
					// If the note has not yet been created on the server, merely update what we will send to the server to create
					if (queueItem.payload._noteId && queueItem.payload._noteId === note._noteId) {
						queueItem.payload = note;
						return -1;
					}
				}

				if (queueItem.endpoint === "notes.edit") {
					// Unqueue previous edits, as they will be rendered obsolete by the new edit
					if (queueItem.payload.noteId && queueItem.payload.noteId === note.noteId) return 1;
				}

				// There's no sense in queueing the action if we don't have a note ID to edit
				if (!note.noteId) return -1;
			});
		});
	},
	_edit: function (note: any) {
		// TODO: Retrieve mixed blob/URL images
		const images: any = [];

		return new Promise<void>((resolve, reject) => {
			ensureValidToken()
				.then(() => {
					const data = {
						Note: {
							NoteId: note.noteId,
							PlantingIds: note.plantingIds,
							NoteTypeId: note.noteTypeId,
							NoteUrgencyId: note.noteUrgencyId,
							NoteText: note.noteText,
							Location: note.location,
							Accuracy: note.accuracy,
							Images: images,
						},
						ObservationsToAdd: note.observationsToAdd,
						ObservationsToRemove: note.observationsToRemove,
						// TODO: ImagesToAdd, ImagesToRemove
					};

					seedgreenAPI
						.post("LotDetails/EditNote/" + note.noteId, data)
						.then((response) => response.data)
						.then((_createdData) => {
							// TODO: Fill in images with the created image URLs
							// TODO: Handle local storage images
							resolve();
						})
						.catch((e) => {
							reject(e);
						});
				})
				.catch((e) => {
					reject(e);
				});
		});
	},

	// Delete
	delete: function (note: any) {
		// Update local state
		store.dispatch("deleteNote", note);

		// Delete blob images
		if (note._noteId) localForage.removeItem(`note/_${note._noteId}/images`);
		else if (note.noteId) localForage.removeItem(`note/${note.noteId}/images`);

		// Enqueue API-related mutations
		OfflineQueue.add("notes.delete", note, function duplicateMerger(queueItem) {
			if (queueItem.endpoint === "notes.create" || queueItem.endpoint === "notes.edit") {
				// Unqueue actions that are rendered obsolete by the new action
				if (queueItem.payload.noteId && queueItem.payload.noteId === note.noteId) return 1;

				// If the note has not yet been created on the server, unqueue all actions related to it,
				// as the delete means we never need to hit the server to begin with
				if (queueItem.payload._noteId && queueItem.payload._noteId === note._noteId) return 2;
			}

			// There's no sense in queueing the action if we don't have a note ID to delete
			if (!note.noteId) return -1;
		});
	},
	_delete: function (note: any) {
		return new Promise<void>((resolve, reject) => {
			ensureValidToken()
				.then(() => {
					seedgreenAPI
						.delete("LotDetails/DeleteNote/" + note.noteId)
						.then(() => {
							resolve();
						})
						.catch((e) => {
							reject(e);
						});
				})
				.catch((e) => {
					reject(e);
				});
		});
	},
};

export default bundle;
