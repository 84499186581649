import { experimental_createPersister, PERSISTER_KEY_PREFIX } from "@tanstack/query-persist-client-core";
import { hashKey, QueryClient, QueryKey, SetDataOptions, Updater } from "@tanstack/vue-query";
import { MaybeRefDeep } from "@tanstack/vue-query/build/legacy/types";
import { newIdbAsyncStorage } from "./idb-storage";
import { captureException } from "@sentry/vue";

/**
 * This is the mobile query client. It is a custom vue-query client that uses the persistence
 * wrapper around queryFn that saves the result of a query to IndexedDB. This file also modifies
 * the `setQueryData` function so that it will update the persisted data in IndexedDB properly,
 * since vue-query doesn't support this yet (keep an eye out for this in future versions).
 */

const QUERY_DEFAULT_MEMORY_GC_TIME = 5 * 60 * 1000; // 5 minutes
const MAX_IDB_PERSISTED_AGE = 3 * 24 * 60 * 60 * 1000; // 3 days

export const idbStorage = newIdbAsyncStorage("db", "query-client");

/**
 * Queries that should *not* be persisted to IndexedDB
 */
const queryKeysToSkipPersist: Set<ReturnType<typeof hashKey>> = new Set([["verify-token"]].map(hashKey));

/**
 * Custom query client for mobile that handles persisted data
 */
export const mobileQueryClient = new QueryClient({
	defaultOptions: {
		queries: {
			gcTime: QUERY_DEFAULT_MEMORY_GC_TIME,
			persister: experimental_createPersister({
				storage: idbStorage,
				maxAge: MAX_IDB_PERSISTED_AGE,
				serialize: (persistedQuery) => persistedQuery, // We don't need to json serialize since we're using IDB
				deserialize: (cachedData) => cachedData,
				filters: {
					predicate: (query) => !queryKeysToSkipPersist.has(hashKey(query.queryKey)),
				},
			}),
		},
	},
});

/**
 * We replace the default `setQueryData` of the query client with our own that also
 * persists data to IndexedDB
 * @param queryKey Key of query
 * @param updater Data update function, or new data
 */
const _setQueryData = mobileQueryClient.setQueryData.bind(mobileQueryClient);
mobileQueryClient.setQueryData = async (
	queryKey: QueryKey,
	updater: Updater<any, any>,
	options?: MaybeRefDeep<SetDataOptions>,
) => {
	if (typeof updater === "function") {
		await updateQueryDataPersisted(queryKey, updater, options);
	} else {
		await setQueryDataPersisted(queryKey, updater, options);
	}
};

/**
 * Get the storage key for a query that vue-query will use in the persistence wrapper
 */
export function getQueryStorageKey(key: QueryKey) {
	return `${PERSISTER_KEY_PREFIX}-${hashKey(key)}`;
}

/**
 * Overwrite the contents of a query cache with data
 * @param key
 * @param data
 * @param options
 */
async function setQueryDataPersisted(key: QueryKey, data: any, options?: MaybeRefDeep<SetDataOptions>) {
	const storageKey = getQueryStorageKey(key);
	// Update persisted data before updating cache
	const storedQuery = await idbStorage.getItem(storageKey);

	if (storedQuery) {
		storedQuery.state.data = data;
		try {
			await idbStorage.setItem(storageKey, storedQuery);
		} catch (error) {
			captureException(error);
			// If we can't persist for some reason, clear the persisted data so it's invalid and must be refetched
			await idbStorage.removeItem(storageKey);
			throw error;
		}
	}

	// Call the original setQueryData function and let TanStack handle the rest
	_setQueryData(key, data, options);
}

/**
 * Update the contents of a query cache using an updater function
 * @param key
 * @param update function that takes the current data and should return the updated data
 * @param options
 * @returns
 */
async function updateQueryDataPersisted(
	key: QueryKey,
	update: (data: any) => any,
	options?: MaybeRefDeep<SetDataOptions>,
) {
	// Grab current data from queryClient (generally more accurate than IndexedDB)
	const data = mobileQueryClient.getQueryData(key);

	let updatedData;
	try {
		updatedData = update(data);
	} catch (e) {
		captureException(e);
		throw e;
	}

	return setQueryDataPersisted(key, updatedData, options);
}
